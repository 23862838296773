import styled from "styled-components";

// Create a styled img component
const HeadImg = styled.img`
  width: 150px;
  display: block;
  margin: auto;
  padding: 10px 0px;

  @media screen and (min-width: 768px){
    width: 220px;
  }
`;

export default function Headline() {
  return (
    <>
      <HeadImg src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/009b24f6-5051-4bac-b3a3-bd886e195600/public" />
    </>
  );
}
